import React from 'react';

const ApplicationOpCodeCategory = () => {
    return (
        <div>
            Op Code Category
        </div>
    );
};

export default ApplicationOpCodeCategory;